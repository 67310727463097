import type { DeepReadonly } from 'ts-essentials'
import { ZodIssueCode } from 'zod'
import { $t } from '../common/translate'
import { messages } from '../messages'

export type StandardErrorCode =
  | 'DeleteFailed'
  | 'Duplicate'
  | 'FieldValidationError'
  | 'Forbidden'
  | 'InvalidField'
  | 'InvalidFieldForInsertUpdate'
  | 'InvalidSession'
  | 'InvalidType'
  | 'NotFound'
  | 'RecordFailed'
  | 'RequiredFieldMissing'
  | 'UnknownError'

export type StandardError = DeepReadonly<{
  code: StandardErrorCode
  fields?: string[]
  message: string
}>

/**
 * Translate Zod issue code to human readable string
 * @param code the Zod issue code
 * @returns the translated issue code
 */
// eslint-disable-next-line complexity
export function translateZodIssueCode(code: ZodIssueCode) {
  switch (code) {
    case ZodIssueCode.invalid_arguments:
      return $t(messages.validation.arguments)
    case ZodIssueCode.invalid_date:
      return $t(messages.validation.date)
    case ZodIssueCode.invalid_enum_value:
    case ZodIssueCode.invalid_intersection_types:
    case ZodIssueCode.invalid_literal:
    case ZodIssueCode.invalid_return_type:
    case ZodIssueCode.invalid_string:
    case ZodIssueCode.invalid_type:
    case ZodIssueCode.invalid_union:
    case ZodIssueCode.invalid_union_discriminator:
    case ZodIssueCode.not_finite:
    case ZodIssueCode.not_multiple_of:
    case ZodIssueCode.unrecognized_keys:
      return $t(messages.validation.format)
    case ZodIssueCode.too_big:
      return $t(messages.validation.tooBig)
    case ZodIssueCode.too_small:
      return $t(messages.validation.tooSmall)
    default:
      return $t(messages.validation.error)
  }
}

/**
 * @function Translate standard error code to human readable string
 * @param code the standard error code
 * @returns the translated error code
 */
// eslint-disable-next-line complexity
export function translateStandardErrorCode(code: StandardErrorCode) {
  switch (code) {
    case 'DeleteFailed':
      return $t(messages.errors.deleteFailed)
    case 'Duplicate':
      return $t(messages.errors.duplicate)
    case 'FieldValidationError':
      return $t(messages.errors.fieldValidationError)
    case 'Forbidden':
      return $t(messages.errors.forbidden)
    case 'InvalidField':
    case 'InvalidFieldForInsertUpdate':
      return $t(messages.errors.invalidField)
    case 'InvalidSession':
      return $t(messages.errors.invalidSession)
    case 'InvalidType':
      return $t(messages.errors.invalidType)
    case 'NotFound':
      return $t(messages.errors.notFound)
    case 'RecordFailed':
      return $t(messages.errors.recordFailed)
    case 'RequiredFieldMissing':
      return $t(messages.errors.requiredFieldMissing)
    default:
      return $t(messages.errors.unknown)
  }
}
