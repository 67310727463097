import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Outlet, createRootRouteWithContext } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { QUERY_DEV_TOOLS, ROUTER_DEV_TOOLS } from '../constants/environnement.constant'
import { authenticateGuard } from '../middlewares/authenticate.middleware'
import type { RouterContext } from '../providers/router.provider'
import { NotFoundPage } from './__404'

export const Route = createRootRouteWithContext<RouterContext>()({
  beforeLoad: async ({ context }) => authenticateGuard(context),
  component: RootComponent,
  notFoundComponent: NotFoundPage,
})

function RootComponent() {
  return (
    <>
      <div className="flex h-screen flex-col">
        <Outlet />
      </div>
      {Boolean(QUERY_DEV_TOOLS) && <ReactQueryDevtools buttonPosition="top-right" />}
      {Boolean(ROUTER_DEV_TOOLS) && <TanStackRouterDevtools position="bottom-right" />}
    </>
  )
}
