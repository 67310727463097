/* eslint-disable no-restricted-syntax, @typescript-eslint/no-empty-function */
/**
 * A function that returns void, handy for initializing variables.
 */
// biome-ignore lint/suspicious/noEmptyBlockStatements: <explanation>
export function functionReturningVoid(): void {}
/* eslint-enable no-restricted-syntax, @typescript-eslint/no-empty-function */

/**
 * A function that returns `undefined`, handy for initializing variables.
 * @returns `undefined`
 */
export function functionReturningUndefined() {
  // eslint-disable-next-line unicorn/no-useless-undefined
  return undefined
}
