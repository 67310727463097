import type { DeepReadonly } from 'ts-essentials'
import type { RouterContext } from '../providers/router.provider'
import { getMe, login } from '../services/auth.service'

export async function authenticateGuard(context: DeepReadonly<RouterContext>) {
  if (!context.auth) throw new Error('Auth context is not provided')

  if (!context.auth.isAuthenticated) {
    const user = await getMe()

    if (user) context.auth.setUser(user)
    else login()
  }
}
