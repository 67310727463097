/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable unicorn/no-object-as-default-parameter */
/* eslint-disable camelcase */
import type { DeepReadonly } from 'ts-essentials'
import { type ZodIssueOptionalMessage, z } from 'zod'
import { $t } from '../common/translate'
import { messages } from '../messages'
import { translateZodIssueCode } from './error'

export const ibanLength = 27
export const maxCityLength = 60
export const maxStreetLength = 100
export const postalCodeLength = 5
export const sirenLength = 9
export const siretLength = 14
export const nafLength = 5
export const maxCommentLength = 500
export const minBicLength = 8
export const maxBicLength = 11
export const defaultStringLength = 100
export const requiredErrorMessage = $t(messages.validation.required)

export function bicValidation(options?: Readonly<{ required: true }>): z.ZodString
export function bicValidation(options?: Readonly<{ required: false }>): z.ZodNullable<z.ZodUnion<[z.ZodString, z.ZodLiteral<''>]>>
/**
 * Validate a BIC
 * @param options the validation options
 * @param options.required whether the field is required
 * @returns the BIC validation schema
 */
export function bicValidation(options: Readonly<{ required: boolean }> = { required: true }) {
  const bicSchema = z
    .string({
      invalid_type_error: requiredErrorMessage,
      required_error: requiredErrorMessage,
    })
    .min(minBicLength, $t(messages.validation.minChar, { count: minBicLength }))
    .max(maxBicLength, $t(messages.validation.maxChar, { count: maxBicLength }))

  if (!options.required) return bicSchema.or(z.literal('')).nullable()

  return bicSchema
}

export function cityValidation(options?: Readonly<{ required: true }>): z.ZodString
export function cityValidation(options?: Readonly<{ required: false }>): z.ZodNullable<z.ZodUnion<[z.ZodString, z.ZodLiteral<''>]>>
/**
 * Validate a city
 * @param options the validation options
 * @param options.required whether the field is required
 * @returns the city validation schema
 */
export function cityValidation(options: Readonly<{ required: boolean }> = { required: true }) {
  const citySchema = z

    .string({ required_error: requiredErrorMessage })
    .min(1, requiredErrorMessage)
    .max(maxCityLength, $t(messages.validation.maxChar, { count: maxCityLength }))

  if (!options.required) return citySchema.or(z.literal('')).nullable()

  return citySchema
}

export function civilityValidation(options?: Readonly<{ required: true }>): z.ZodEnum<['M.', 'Mme', 'Mx']>
export function civilityValidation(options?: Readonly<{ required: false }>): z.ZodUnion<[z.ZodEnum<['M.', 'Mme', 'Mx']>, z.ZodLiteral<''>]>
/**
 * Validate a civility
 * @param options the validation options
 * @param options.required whether the field is required
 * @returns the civility validation schema
 */
export function civilityValidation(options: Readonly<{ required: boolean }> = { required: true }) {
  const civilitySchema = z.enum(['M.', 'Mme', 'Mx'], {
    // eslint-disable-next-line jsdoc/require-jsdoc
    errorMap: () => ({ message: $t(messages.validation.civility) }),
  })

  if (!options.required) return civilitySchema.or(z.literal(''))

  return civilitySchema
}

export function commentValidation(options?: Readonly<{ required: true }>): z.ZodString
export function commentValidation(options?: Readonly<{ required: false }>): z.ZodNullable<z.ZodUnion<[z.ZodString, z.ZodLiteral<''>]>>
/**
 * Validate a comment
 * @param options the validation options
 * @param options.required whether the field is required
 * @returns the comment validation schema
 */
export function commentValidation(options: Readonly<{ required: boolean }> = { required: true }) {
  const commentSchema = z
    .string({ required_error: requiredErrorMessage })
    .min(1, requiredErrorMessage)
    .max(maxCommentLength, $t(messages.validation.maxChar, { count: maxCommentLength }))

  if (!options.required) return commentSchema.or(z.literal('')).nullable()

  return commentSchema
}

export function emailValidation(options?: Readonly<{ required: true }>): z.ZodString
export function emailValidation(options?: Readonly<{ required: false }>): z.ZodNullable<z.ZodUnion<[z.ZodString, z.ZodLiteral<''>]>>
/**
 * Validate an email
 * @param options the validation options
 * @param options.required whether the field is required
 * @returns the email validation schema
 */
export function emailValidation(options: Readonly<{ required: boolean }> = { required: true }) {
  const emailSchema = z.string({ required_error: requiredErrorMessage }).email({ message: $t(messages.validation.email) })

  if (!options.required) return emailSchema.or(z.literal('')).nullable()

  return emailSchema
}

export function ibanValidation(options?: Readonly<{ required: true }>): z.ZodString
export function ibanValidation(options?: Readonly<{ required: false }>): z.ZodNullable<z.ZodUnion<[z.ZodString, z.ZodLiteral<''>]>>
/**
 * Validate an IBAN
 * @param options the validation options
 * @param options.required whether the field is required
 * @returns the IBAN validation schema
 */
export function ibanValidation(options: Readonly<{ required: boolean }> = { required: true }) {
  const ibanSchema = z
    .string({ required_error: requiredErrorMessage })
    .length(ibanLength, $t(messages.validation.nbChar, { count: ibanLength }))
    .startsWith('FR', { message: $t(messages.validation.startWith, { value: 'FR' }) })

  if (!options.required) return ibanSchema.or(z.literal('')).nullable()

  return ibanSchema
}

export const mobilePhoneRegex = /^(?:(?:\+|00)33|0)[67]\d{8}$/u

export function mobilePhoneValidation(options?: Readonly<{ required: true }>): z.ZodString
export function mobilePhoneValidation(options?: Readonly<{ required: false }>): z.ZodNullable<z.ZodString>
/**
 * Validate a mobile phone
 * @param options the validation options
 * @param options.required whether the field is required
 * @returns the mobile phone validation schema
 */
export function mobilePhoneValidation(options: Readonly<{ required: boolean }> = { required: true }) {
  const mobilePhoneSchema = z.custom((input: unknown) => {
    if (typeof input !== 'string') return false
    if (!(options.required || input)) return true

    return Boolean(mobilePhoneRegex.test(input.replace(/\s/gu, '')))
  }, $t(messages.validation.mobilePhone))

  if (!options.required) return mobilePhoneSchema.nullable()

  return mobilePhoneSchema
}

export const nafRegex = /\D/u

export function nafCodeValidation(options?: Readonly<{ required: true }>): z.ZodEffects<z.ZodString>
export function nafCodeValidation(options?: Readonly<{ required: false }>): z.ZodNullable<z.ZodUnion<[z.ZodEffects<z.ZodString>, z.ZodLiteral<''>]>>
/**
 * Validate a NAF code
 * @param options the validation options
 * @param options.required whether the field is required
 * @returns the NAF code validation schema
 */
export function nafCodeValidation(options: Readonly<{ required: boolean }> = { required: true }) {
  const nafCodeSchema = z
    .string({ required_error: requiredErrorMessage })
    .length(nafLength, $t(messages.validation.nbChar, { count: nafLength }))
    .refine((hasLetter: string) => nafRegex.test(hasLetter), {
      message: $t(messages.validation.nbNumberNbLetter, { letter: 1, number: 4 }),
    })

  if (!options.required) return nafCodeSchema.or(z.literal('')).nullable()

  return nafCodeSchema
}

/**
 * Validate a positive float number
 * @param options the validation options
 * @returns the positive float number validation schema
 */
export function floatPositiveValidation(options?: Readonly<{ max?: number; min?: number }>) {
  const minimumValue = options?.min ?? 0
  const maximumValue = options?.max ?? Number.POSITIVE_INFINITY

  const baseSchema = {
    invalid_type_error: $t(messages.validation.number),
    required_error: requiredErrorMessage,
  }

  return z.preprocess(
    number => Number.parseInt(z.coerce.string(baseSchema).parse(number), 10),
    z
      .number(baseSchema)
      .nonnegative({ message: $t(messages.validation.positiveValue) })
      .min(minimumValue, { message: $t(messages.validation.minValue, { value: minimumValue }) })
      .max(maximumValue, { message: $t(messages.validation.maxValue, { value: maximumValue }) }),
  )
}

/**
 * Validate a float number
 * @param options the validation options
 * @returns the float number validation schema
 */
export function floatValidation(options?: Readonly<{ max?: number; min?: number }>) {
  const minimumValue = options?.min ?? Number.NEGATIVE_INFINITY
  const maximumValue = options?.max ?? Number.POSITIVE_INFINITY

  const baseSchema = {
    invalid_type_error: $t(messages.validation.number),
    required_error: requiredErrorMessage,
  }

  return z.preprocess(
    number => Number.parseInt(z.coerce.string(baseSchema).parse(number), 10),
    z
      .number(baseSchema)
      .min(minimumValue, { message: $t(messages.validation.minValue, { value: minimumValue }) })
      .max(maximumValue, { message: $t(messages.validation.maxValue, { value: maximumValue }) }),
  )
}

/**
 * Validate a positive integer number
 * @param options the validation options
 * @returns the positive integer number validation schema
 */
export function integerPositiveValidation(options?: Readonly<{ max?: number; min?: number }>) {
  const minimumValue = options?.min ?? 0
  const maximumValue = options?.max ?? Number.POSITIVE_INFINITY

  const baseSchema = {
    invalid_type_error: $t(messages.validation.number),
    required_error: requiredErrorMessage,
  }

  return z.preprocess(
    // biome-ignore lint/performance/useTopLevelRegex: we should check the number format
    number => (/^\d+\.\d+$/u.test(String(number)) ? Number.NaN : Number.parseInt(z.coerce.string(baseSchema).parse(number), 10)),
    z
      .number(baseSchema)
      .int()
      .nonnegative({ message: $t(messages.validation.positiveValue) })
      .min(minimumValue, { message: $t(messages.validation.minValue, { value: minimumValue }) })
      .max(maximumValue, { message: $t(messages.validation.maxValue, { value: maximumValue }) }),
  )
}

/**
 * Validate an integer number
 * @param options the validation options
 * @returns the integer number validation schema
 */
export function integerValidation(options?: Readonly<{ max?: number; min?: number }>) {
  const minimumValue = options?.min ?? Number.NEGATIVE_INFINITY
  const maximumValue = options?.max ?? Number.POSITIVE_INFINITY

  const baseSchema = {
    invalid_type_error: $t(messages.validation.number),
    required_error: requiredErrorMessage,
  }

  return z.preprocess(
    // biome-ignore lint/performance/useTopLevelRegex: we should check the number format
    number => (/^\d+\.\d+$/u.test(String(number)) ? Number.NaN : Number.parseInt(z.coerce.string(baseSchema).parse(number), 10)),
    z
      .number(baseSchema)
      .int()
      .min(minimumValue, { message: $t(messages.validation.minValue, { value: minimumValue }) })
      .max(maximumValue, { message: $t(messages.validation.maxValue, { value: maximumValue }) }),
  )
}

export const phoneRegex = /^0[1-5]\d{8}$/u

export function phoneValidation(options?: Readonly<{ required: true }>): z.ZodString
export function phoneValidation(options?: Readonly<{ required: false }>): z.ZodNullable<z.ZodUnion<[z.ZodEffects<z.ZodString>, z.ZodLiteral<''>]>>
/**
 * Validate a phone number
 * @param options the validation options
 * @param options.required whether the field is required
 * @returns the phone number validation schema
 */
export function phoneValidation(options: Readonly<{ required: boolean }> = { required: true }) {
  const phoneSchema = z.custom<string>((input: unknown): boolean => {
    if (typeof input !== 'string') return false
    if (!(options.required || input)) return true

    return Boolean(phoneRegex.test(input.replace(/\s/gu, '')))
  }, $t(messages.validation.phone))

  if (!options.required) return phoneSchema.or(z.literal('')).nullable()

  return phoneSchema
}

export const postalCodeRegex = /^\d{5}$/u

export function postalCodeValidation(options?: Readonly<{ required: true }>): z.ZodEffects<z.ZodString>
export function postalCodeValidation(options?: Readonly<{ required: false }>): z.ZodNullable<z.ZodUnion<[z.ZodEffects<z.ZodString>, z.ZodLiteral<''>]>>
/**
 * Validate a postal code
 * @param options the validation options
 * @param options.required whether the field is required
 * @returns the postal code validation schema
 */
export function postalCodeValidation(options: Readonly<{ required: boolean }> = { required: true }) {
  const postalCodeSchema = z.string({ required_error: requiredErrorMessage }).refine(
    (value: string) => {
      if (!(options.required || value)) return true

      return value.length === postalCodeLength && postalCodeRegex.test(value)
    },
    $t(messages.validation.nbDigit, { count: postalCodeLength }),
  )

  if (!options.required) return postalCodeSchema.or(z.literal('')).nullable()

  return postalCodeSchema
}

export const sirenRegex = /^\d{9}$/u

export function sirenValidation(options?: Readonly<{ required: true }>): z.ZodEffects<z.ZodString>
export function sirenValidation(options?: Readonly<{ required: false }>): z.ZodNullable<z.ZodUnion<[z.ZodEffects<z.ZodString>, z.ZodLiteral<''>]>>
/**
 * Validate a SIREN
 * @param options the validation options
 * @param options.required whether the field is required
 * @returns the SIREN validation schema
 */
export function sirenValidation(options: Readonly<{ required: boolean }> = { required: true }) {
  const sirenSchema = z.string({ required_error: requiredErrorMessage }).refine(
    (value: string) => {
      if (!(options.required || value)) return true

      return value.length === sirenLength && sirenRegex.test(value)
    },
    $t(messages.validation.nbDigit, { count: sirenLength }),
  )

  if (!options.required) return sirenSchema.or(z.literal('')).nullable()

  return sirenSchema
}

export const siretRegex = /^\d{14}$/u

export function siretValidation(options?: Readonly<{ required: true }>): z.ZodEffects<z.ZodString>
export function siretValidation(options?: Readonly<{ required: false }>): z.ZodNullable<z.ZodUnion<[z.ZodEffects<z.ZodString>, z.ZodLiteral<''>]>>
/**
 * Validate a SIRET
 * @param options the validation options
 * @param options.required whether the field is required
 * @returns the SIRET validation schema
 */
export function siretValidation(options: Readonly<{ required: boolean }> = { required: true }) {
  const siretSchema = z.string({ required_error: requiredErrorMessage }).refine(
    (value: string) => {
      if (!(options.required || value)) return true
      return value.length === siretLength && siretRegex.test(value)
    },
    $t(messages.validation.nbDigit, { count: siretLength }),
  )

  if (!options.required) return siretSchema.or(z.literal('')).nullable()

  return siretSchema
}

export function streetValidation(options?: Readonly<{ required: true }>): z.ZodString
export function streetValidation(options?: Readonly<{ required: false }>): z.ZodNullable<z.ZodUnion<[z.ZodString, z.ZodLiteral<''>]>>
/**
 * Validate a street
 * @param options the validation options
 * @param options.required whether the field is required
 * @returns the street validation schema
 */
export function streetValidation(options: Readonly<{ required: boolean }> = { required: true }) {
  const streetSchema = z
    .string({ required_error: requiredErrorMessage })
    .min(1, requiredErrorMessage)
    .max(maxStreetLength, $t(messages.validation.maxChar, { count: maxStreetLength }))

  if (!options.required) return streetSchema.or(z.literal('')).nullable()

  return streetSchema
}

export function stringValidation(options?: Readonly<{ max?: number; min?: number; required: true }>): z.ZodString
export function stringValidation(
  options?: Readonly<{ max?: number; min?: number; required?: false }>,
): z.ZodNullable<z.ZodUnion<[z.ZodString, z.ZodLiteral<''>]>>
/**
 * Validate a string
 * @param options the validation options
 * @param options.max the maximum length of the string
 * @param options.min the minimum length of the string
 * @param options.required whether the field is required
 * @returns the string validation schema
 */
export function stringValidation(
  options: Readonly<{
    max?: number
    min?: number
    required?: boolean
  }> = { required: true },
) {
  const stringSchema = z
    .string({
      invalid_type_error: $t(messages.validation.string),
      required_error: requiredErrorMessage,
    })
    .min(options.min ?? 1, options.min === undefined ? requiredErrorMessage : $t(messages.validation.minChar, { count: options.min }))
    .max(options.max ?? defaultStringLength, $t(messages.validation.maxChar, { count: options.max ?? defaultStringLength }))

  if (options.required === false) return stringSchema.or(z.literal('')).nullable()

  return stringSchema
}

export function dateValidation(options?: Readonly<{ required: true }>): z.ZodDate
export function dateValidation(options?: Readonly<{ required: false }>): z.ZodNullable<z.ZodUnion<[z.ZodDate, z.ZodLiteral<''>]>>
/**
 * Validate a date
 * @param options the validation options
 * @param options.required whether the field is required
 * @returns the date validation schema
 */
export function dateValidation(options: Readonly<{ required: boolean }> = { required: true }) {
  const dateSchema = z.date({
    // eslint-disable-next-line jsdoc/require-jsdoc
    errorMap: (issue: DeepReadonly<ZodIssueOptionalMessage>) => ({
      message: translateZodIssueCode(issue.code),
    }),
  })

  if (!options.required) return dateSchema.or(z.literal('')).nullable()

  return dateSchema
}

/**
 * Add rules to validate that the start date is before end date
 * You need to chain pipe function to use it like: schema.pipe(refinePeriodValidation(schema)).safeParse(state...)
 * To see some examples of implementation look at validation.utils.tests.ts
 * @param schema the zod schema
 * @param options the validation options
 * @returns the zod schema with the rules
 */
export function refinePeriodValidation(
  schema: DeepReadonly<z.ZodSchema>,
  options?: Readonly<{
    endDateFieldName?: string
    startDateFieldName?: string
  }>,
) {
  const endDate = options?.endDateFieldName ?? 'endDate'
  const startDate = options?.startDateFieldName ?? 'startDate'

  return (
    schema
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      .refine(data => data[endDate] > data[startDate], {
        message: $t(messages.validation.endDateBeforeStartDate),
        path: [String(endDate)],
      })
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      .refine(data => data[startDate] < data[endDate], {
        message: $t(messages.validation.startDateAfterEndDate),
        path: [String(startDate)],
      })
  )
}
