import { isAxiosError } from 'axios'

type ErrorWithMessage = { message: string }

// eslint-disable-next-line no-restricted-syntax
function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return typeof error === 'object' && error !== null && 'message' in error && typeof error.message === 'string'
}

export function getErrorMessage(error: unknown) {
  if (isAxiosError<{ message: string }>(error)) return error.response?.data.message
  if (error instanceof Error) return error.message
  if (isErrorWithMessage(error)) return error.message

  return String(error === undefined ? '' : error)
}
