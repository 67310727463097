/* eslint-disable new-cap */
import 'reflect-metadata'
import { Type } from 'class-transformer'
import { IsNumber, IsOptional, Min } from 'class-validator'
import type { ConsumptionProfilePower } from './delivery-point'
import type { EndpointConnectionPower } from './endpoint-connection'

export const temporalClassesPower = ['BASE', 'HP', 'HC', 'PTE', 'HPH', 'HCH', 'HPE', 'HCE'] as const
export type TemporalClassPower = (typeof temporalClassesPower)[number]

/**
 * @classdesc The power temporal class DTO
 */
// eslint-disable-next-line no-restricted-syntax
export class PowerTemporalClassDto {
  @IsOptional()
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  public readonly base?: number

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  public readonly hc?: number

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  public readonly hce?: number

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  public readonly hch?: number

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  public readonly hp?: number

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  public readonly hpe?: number

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  public readonly hph?: number

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  public readonly pte?: number
}

/**
 * Get the standard power temporal classes
 * @param endpointConnection the endpoint connection power
 * @returns the standard power temporal classes
 */
export function getStandardPowerTemporalClasses(endpointConnection: EndpointConnectionPower) {
  switch (endpointConnection) {
    case 'BTINF':
      return ['BASE'] as const
    case 'BTSUP':
      return ['HPH', 'HCH', 'HPE', 'HCE'] as const
    case 'HTA':
      return ['PTE', 'HPH', 'HCH', 'HPE', 'HCE'] as const
    default:
      throw new Error(`[getStandardPowerTemporalClasses]: ${String(endpointConnection)} endpoint connection is not supported`)
  }
}

/**
 * Get the standard energy temporal classes
 * @param endpointConnection the endpoint connection power
 * @param consumptionProfile the consumption profil
 * @returns the standard energy temporal classes
 */
export function getStandardEnergyTemporalClasses(endpointConnection: EndpointConnectionPower, consumptionProfile: ConsumptionProfilePower) {
  switch (endpointConnection) {
    case 'BTINF':
      return ['CU4', 'MU4', 'MUDT'].includes(consumptionProfile) ? (['HPH', 'HCH', 'HPE', 'HCE'] as const) : (['BASE'] as const)
    case 'BTSUP':
      return ['HPH', 'HCH', 'HPE', 'HCE'] as const
    case 'HTA':
      return ['PTE', 'HPH', 'HCH', 'HPE', 'HCE'] as const
    default:
      throw new Error(`[getStandardEnergyTemporalClasses]: ${String(endpointConnection)} endpoint connection is not supported`)
  }
}
