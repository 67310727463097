import { palette } from '@collectif-energie/ui'
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { frFR } from '@mui/material/locale'
import { createTheme } from '@mui/material/styles'
import type { GridRowClassNameParams } from '@mui/x-data-grid'
import { frFR as dataGridFrFr } from '@mui/x-data-grid/locales/frFR'
import { frFR as datePickerFrFr } from '@mui/x-date-pickers/locales/frFR'
import { type ClassNameValue, twMerge } from 'tailwind-merge'
import type { DeepReadonly } from 'ts-essentials'
import { components } from './theme-component.utils'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    active: true
  }
}

export function mergeClassNames(...inputs: ClassNameValue[]) {
  return twMerge(inputs)
}

export function getRowClassName(parameters: DeepReadonly<GridRowClassNameParams>) {
  const modulo = 2
  return parameters.indexRelativeToCurrentPage % modulo === 0 ? 'bg-neutral-50' : 'bg-neutral-200'
}
declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    unstyled: true
  }
  interface ChipPropsColorOverrides {
    orange: true
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    orange: Palette['primary']
  }
  // allow configuration using `createTheme`
  interface PaletteOptions {
    orange?: PaletteOptions['primary']
  }
}

let theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: ['proxima-nova'].join(','),
  },
})

const themePalette = {
  error: {
    light: palette.red[200],
    main: palette.red[500],
  },
  orange: theme.palette.augmentColor({
    color: {
      main: palette.orange[500],
    },
    name: 'orange',
  }),
  primary: {
    dark: palette.blue[700],
    light: palette.blue[200],
    main: palette.blue[500],
  },
  text: {
    primary: palette.neutral[800],
    secondary: palette.neutral[800],
  },
  warning: {
    light: palette.yellow[200],
    main: palette.yellow[500],
  },
}

theme = createTheme(theme, { components, palette: themePalette }, dataGridFrFr, datePickerFrFr, frFR)

export { theme }
