import { cn } from '@collectif-energie/utils'
import * as SeparatorPrimitive from '@radix-ui/react-separator'
import * as React from 'react'

const Separator = React.forwardRef<React.ElementRef<typeof SeparatorPrimitive.Root>, React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>>(
  ({ className, decorative = true, orientation = 'horizontal', ...props }, ref) => (
    <SeparatorPrimitive.Root
      className={cn('shrink-0 bg-muted', orientation === 'horizontal' ? 'h-px w-auto' : 'h-auto w-px', className)}
      decorative={decorative}
      orientation={orientation}
      ref={ref}
      {...props}
    />
  ),
)
Separator.displayName = SeparatorPrimitive.Root.displayName

export { Separator }
