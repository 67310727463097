import { colors } from '@collectif-energie/ui'
import { $t, messages } from '@collectif-energie/utils'
import { Divider } from '@mui/material'
import type { ReactNode } from 'react'
import type { DeepReadonly } from 'ts-essentials'
import bolt from '../../assets/svg/bolt.svg?react'
import fire from '../../assets/svg/fire.svg?react'
import book from '../../assets/svg/open-book.svg?react'
import { AppNavigation } from './app-navigation'
import { AppUserSettings } from './app-user-settings'

const links = [
  {
    basePath: '/electrique',
    disabled: true,
    icon: bolt,
    label: $t(messages.business.electricity),
    to: '/electrique/tableau-de-bord',
  },
  {
    basePath: '/gaz',
    disabled: false,
    icon: fire,
    label: $t(messages.business.gas),
    to: '/gaz/gestion-des-droits',
  },
  {
    basePath: '/demeter',
    disabled: false,
    icon: book,
    label: $t(messages.business.regulatoryData),
    to: '/demeter/electricite',
  },
]

const sx = { borderBottomWidth: 3, borderColor: colors.primary }

export function AppHeader({ children }: DeepReadonly<{ children?: ReactNode }>) {
  return (
    <>
      <div className="flex w-full items-center justify-end gap-8 p-2">
        {children}
        <AppNavigation links={links} />
        <AppUserSettings />
      </div>
      <Divider sx={sx} />
    </>
  )
}
