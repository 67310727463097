export const messages = {
  actions: {
    apply: 'Appliquer',
    backHome: "Retour à l'accueil",
    close: 'Fermer',
    edit: 'Modifier',
    loading: 'Chargement en cours, merci de patienter...',
    login: 'Se connecter',
    logout: 'Déconnexion',
    next: 'Suivant',
    open: {
      en: 'Open',
      fr: 'Ouvrir',
    },
    previous: 'Précédent',
    save: 'Sauvegarder',
    uploadDocument: 'Déposer un document | Déposer des documents',
  },
  business: {
    dailyMeasurements: 'Mesures quotidiennes',
    electricity: 'Électricité',
    gas: 'Gaz',
    regulatoryData: {
      en: 'Regulatory data',
      fr: 'Données réglementaires',
    },
    rightManagement: 'Gestion des droits',
    technicalDataConsumption: 'DT & Conso',
  },
  errors: {
    deleteFailed: 'La suppression a échoué',
    duplicate: "L'entité existe déjà",
    fieldValidationError: 'Certains champs sont en erreur',
    forbidden: "Vous n'avez pas les droits pour effectuer cette action",
    invalidField: 'Certains champs sont invalides',
    invalidSession: 'La session est invalide',
    invalidType: 'Le type est invalide',
    network: {
      en: 'Network issue',
      fr: 'Problème de connexion',
    },
    notFound: "La ressource n'a pas été trouvée",
    recordFailed: 'La création a échoué',
    requiredFieldMissing: 'Certains champs requis sont manquants',
    unknown: 'Une erreur inconnue est survenue',
  },
  greetings: {
    welcome: 'Coucou {name} !',
  },
  notifications: {
    itemAddedOn: 'Objet ajouté le {time}',
  },
  states: {
    active: 'Actif',
    closed: 'Fermé',
    disabled: 'Désactivé',
    inactive: 'Inactif',
    incomplete: 'Incomplet',
    invalid: 'Invalide',
    invalidRights: 'Droits invalides',
    morePages: 'Plus de pages',
    open: 'Ouvert',
    pending: 'En attente',
    revoked: 'Révoqué',
    unknown: 'Inconnu',
    valid: 'Valide',
    validated: 'Validé',
  },
  validation: {
    arguments: 'Le champ contient des arguments invalides',
    civility: 'La civilité est invalide',
    date: 'La date est invalide',
    email: "Le champ n'est pas une adresse email valide",
    endDateBeforeStartDate: 'La date de fin doit être postérieure à la date de début',
    error: 'Le champ est en erreur',
    format: 'Le format du champ est invalide',
    maxChar: 'Le champ doit contenir un seul caractère | Le champ doit contenir {count} caractères maximum',
    maxValue: 'La valeur doit être inférieure ou égale à {value}',
    minChar: "Le champ doit contenir au moins un caractère | Le champ doit contenir plus d'un caractère | Le champ doit contenir au moins {count} caractères",
    minValue: 'La valeur doit être supérieure ou égale à {value}',
    mobilePhone: "Le champ n'est pas un numéro de téléphone mobile valide (10 chiffres)",
    nbChar: 'Le champ doit comporter un seul caractère | Le champ doit comporter {count} caractères',
    nbDigit: 'Le champ doit comporter un seul chiffre | Le champ doit comporter {count} chiffres',
    nbNumberNbLetter: 'Le champ doit comporter {number} chiffre(s) et {letter} lettre(s)',
    negativeValue: 'La valeur doit être negative',
    number: 'Le champ doit être un nombre valide',
    phone: "Le champ n'est pas un numéro de téléphone fixe valide (10 chiffres)",
    positiveValue: 'La valeur doit être positive',
    required: 'Le champ est obligatoire',
    startDateAfterEndDate: 'La date de début doit être antérieure à la date de fin',
    startWith: 'Le champ doit commencer par {value}',
    string: 'Le champ doit être une chaîne de caractères valide',
    tooBig: 'Le champ contient trop de caractères',
    tooSmall: 'Le champ ne contient pas assez de caractères',
  },
} as const
