import { Button, Icon, Menu, MenuItem, type PopoverOrigin } from '@mui/material'
import { useAtom } from 'jotai'
import React, { useCallback } from 'react'
import type { DeepReadonly } from 'ts-essentials'
import { logout } from '../../services/auth.service'
import { userAtom } from '../../stores/auth.store'
import { mergeClassNames } from '../../utils/theme.utils'

const menuItemStyle = { minWidth: 200 }
const anchorOrigin: PopoverOrigin = {
  horizontal: 'right',
  vertical: 'bottom',
}
const transformOrigin: PopoverOrigin = {
  horizontal: 'right',
  vertical: 'top',
}

// eslint-disable-next-line max-lines-per-function
export function AppUserSettings({ className = '' }: DeepReadonly<{ className?: string }>) {
  const [user] = useAtom(userAtom)

  const [anchorElement, setAnchorElement] = React.useState<HTMLElement | null>()
  const [isToggleArrow, setIsToggleArrow] = React.useState<boolean>(false)

  const isMenuOpen = Boolean(anchorElement)

  const handleToggleArrow = useCallback(() => {
    setIsToggleArrow(!isToggleArrow)
  }, [isToggleArrow])

  const handleOpenMenu = useCallback((event: Readonly<React.MouseEvent<HTMLButtonElement>>) => {
    setAnchorElement(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorElement(undefined)
  }, [])

  const handleDeconnexion = useCallback(async () => {
    await logout()
  }, [])

  return (
    <>
      <div className={mergeClassNames('bg-neutral-50 hover:bg-blue-500', className)}>
        <Button
          className="hover:text-primary-foreground"
          onClick={handleOpenMenu}
          onMouseEnter={handleToggleArrow}
          onMouseOut={handleToggleArrow}
          variant="text"
        >
          <div className="flex items-center gap-4 px-2 transition-all duration-75">
            <Icon>account_circle</Icon>
            <h3 className="text-lg font-bold">{user?.displayname}</h3>
            <Icon className={`transition-transform duration-500 ${isToggleArrow ? 'rotate-180' : ''}`}>keyboard_arrow_up_outlined_icon</Icon>
          </div>
        </Button>
      </div>

      <Menu
        anchorEl={anchorElement}
        anchorOrigin={anchorOrigin}
        data-testid="settings-menu"
        onClose={handleCloseMenu}
        open={isMenuOpen}
        transformOrigin={transformOrigin}
      >
        <MenuItem data-testid="settings-deconnexion" dense onClick={handleDeconnexion} sx={menuItemStyle}>
          <Icon>logout_icon</Icon>
          Déconnexion
        </MenuItem>
      </Menu>
    </>
  )
}
