import OpenApiClientAxios from 'openapi-client-axios'
import document from '../../openapi.json'
/* eslint-disable @typescript-eslint/naming-convention */
import { API_URL } from '../constants/environnement.constant'
import type { Client } from '../types/openapi'

const api = new OpenApiClientAxios({
  axiosConfigDefaults: {
    // biome-ignore lint/style/useNamingConvention: axios convention
    baseURL: API_URL,
    withCredentials: true,
  },
  // @ts-expect-error type issue
  definition: document,
})

await api.init()

export const cosmosApi = await api.getClient<Client>()
