/* eslint-disable max-classes-per-file */
/* eslint-disable new-cap */
import 'reflect-metadata'
import { Type } from 'class-transformer'
import { IsNumber, IsObject, Min, ValidateNested } from 'class-validator'
import { ConsumptionProfileDto } from './consumption-profile'

export const endpointConnections = ['HTA', 'BTSUP', 'BTINF'] as const
export type EndpointConnectionPower = (typeof endpointConnections)[number]

/**
 * @classdesc The endpoint connection DTO
 */
// eslint-disable-next-line no-restricted-syntax
export class EndpointConnectionDto {
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  public readonly btinf!: number

  @IsNumber()
  @Min(0)
  @Type(() => Number)
  public readonly btsup!: number

  @IsNumber()
  @Min(0)
  @Type(() => Number)
  public readonly hta!: number
}

/**
 * @class Consumption Profile by endpoint connection DTO
 */
// eslint-disable-next-line no-restricted-syntax
export class EndpointConnectionConsumptionProfileDto {
  @ValidateNested()
  @IsObject()
  @Type(() => ConsumptionProfileDto)
  public readonly btinf!: ConsumptionProfileDto

  @ValidateNested()
  @IsObject()
  @Type(() => ConsumptionProfileDto)
  public readonly btsup!: ConsumptionProfileDto

  @ValidateNested()
  @IsObject()
  @Type(() => ConsumptionProfileDto)
  public readonly hta!: ConsumptionProfileDto
}

/**
 * Get power consumption profiles by endpoint connection
 * @param endpointConnection the power endpoint connection
 * @returns an array of power consumption profiles
 */
export function getStandardEndpointConnectionConsumptionProfiles(endpointConnection: EndpointConnectionPower) {
  switch (endpointConnection) {
    case 'BTINF':
      return ['CU', 'CU4', 'LU', 'MU4', 'MUDT'] as const
    case 'BTSUP':
    case 'HTA':
      return ['CU', 'LU'] as const
    default:
      throw new Error(`[getStandardEndpointConnectionConsumptionProfiles]: ${String(endpointConnection)} endpoint connection is not supported`)
  }
}
