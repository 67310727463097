import { IconCollectifEnergieText } from '@collectif-energie/ui'
import { Button, SvgIcon } from '@mui/material'
import { createFileRoute } from '@tanstack/react-router'
import { useCallback, useMemo, useState } from 'react'
import background from '../assets/img/background-home.jpeg'
import { AppHeader } from '../components/app/app-header'

const sx = { fontSize: '15vw', height: 'fit-content' }
const unicornStyle = {
  '&:hover': {
    backgroundColor: 'rgb(255 255 255 / var(--tw-bg-opacity))',
    fontWeight: 'bold',
  },
  backgroundColor: 'rgb(255 255 255 / var(--tw-bg-opacity))',
  cursor: 'default',
  fontSize: '1.25rem',
  fontWeight: 'bold',
}

export const Route = createFileRoute('/')({
  component: Home,
})

export function Home() {
  const [isUnicorn, setIsUnicorn] = useState(false)

  const toggleUnicorn = useCallback(() => {
    setIsUnicorn(!isUnicorn)
  }, [isUnicorn])

  const backgroundImage = useMemo(
    () => ({
      backgroundImage: `linear-gradient(${isUnicorn ? 'rgba(0, 0, 0, 0.5), rgba(198,240,185, 0.5), rgba(178,223,253, 0.5), rgba(241,171,198, 0.5), rgba(235,202,243, 0.5)' : 'rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)'}), url(${background})`,
    }),
    [isUnicorn],
  )

  return (
    <div className="flex h-screen flex-col bg-cover bg-center bg-no-repeat" style={backgroundImage}>
      <div className="flex w-full items-center justify-end gap-4">
        <SvgIcon className="absolute left-20 top-4 flex text-primary-foreground" component={IconCollectifEnergieText} sx={sx} />
        <AppHeader />
      </div>

      <div className="flex flex-1 cursor-default">
        <div className="w-6/12 self-center px-16 text-[5em] font-bold leading-tight text-primary-foreground">
          conseiller
          <div className="my-10 w-28 border-y-4" />
          éclairer
        </div>

        <div className="flex w-6/12 justify-end self-end ">
          <div className="flex flex-col items-end">
            <div className="mr-52 size-24 border-8 border-primary" />
            <div className="flex h-20 w-52 items-center justify-center bg-primary-foreground font-bold text-primary">
              <Button color="secondary" onDoubleClick={toggleUnicorn} sx={unicornStyle} variant="outlined">
                Cosmos
              </Button>
            </div>
            <div className="size-20 border-8 border-primary" />
            <div className="mx-28 mb-16 mt-20 size-16 border-8 border-primary" />
          </div>
          <div className="h-72 w-80 self-end bg-primary text-transparent" />
        </div>
      </div>
    </div>
  )
}
