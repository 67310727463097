import { analytics } from '@collectif-energie/utils'
import { ThemeProvider } from '@mui/material/styles'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { FormProvider, useForm } from 'react-hook-form'
import { AuthProvider } from './providers/auth.provider'
import { QueryProvider } from './providers/query.provider'
import { RouterProvider } from './providers/router.provider'
import { SnackbarProvider } from './providers/snackbar.provider'
import { theme } from './utils/theme.utils'
import './styles.css'

const element = document.querySelector('#root')
if (element === null) throw new Error('Root element not found')

await analytics.setupGoogleTagManager('cosmos-front', 'GTM-WCGXJ5F6')

export function Main() {
  const methods = useForm()

  return (
    <StrictMode>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <QueryProvider>
            <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterLuxon}>
              <AuthProvider>
                <FormProvider {...methods}>
                  <RouterProvider />
                </FormProvider>
              </AuthProvider>
            </LocalizationProvider>
          </QueryProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StrictMode>
  )
}

createRoot(element).render(<Main />)
