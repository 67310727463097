/* eslint-disable new-cap */
import 'reflect-metadata'
import { Type } from 'class-transformer'
import { IsObject, IsOptional, ValidateNested } from 'class-validator'
import { PowerTemporalClassDto } from './temporal-class'

/**
 * @class Consumption Profile DTO
 */
// eslint-disable-next-line no-restricted-syntax
export class ConsumptionProfileDto {
  @ValidateNested()
  @IsObject()
  @Type(() => PowerTemporalClassDto)
  public readonly cu!: PowerTemporalClassDto

  @IsOptional()
  @ValidateNested()
  @IsObject()
  @Type(() => PowerTemporalClassDto)
  public readonly cu4?: PowerTemporalClassDto

  @ValidateNested()
  @IsObject()
  @Type(() => PowerTemporalClassDto)
  public readonly lu!: PowerTemporalClassDto

  @IsOptional()
  @ValidateNested()
  @IsObject()
  @Type(() => PowerTemporalClassDto)
  public readonly mu4?: PowerTemporalClassDto

  @IsOptional()
  @ValidateNested()
  @IsObject()
  @Type(() => PowerTemporalClassDto)
  public readonly mudt?: PowerTemporalClassDto
}
