import { type QueryClient, QueryClientContext } from '@tanstack/react-query'
import { RouterProvider as Provider, type RoutesByPath, createRouter } from '@tanstack/react-router'
import { useContext, useMemo } from 'react'
import { routeTree } from '../route-tree.gen'
import { NotFoundPage } from '../routes/__404'
import { AuthContext, type AuthContextType } from './auth.provider'

/** to use the router inside components => const router = useRouter(); */
export type Routes = RoutesByPath<typeof routeTree>

export type RouterContext = {
  auth: AuthContextType | undefined
  query: QueryClient
}

export function RouterProvider() {
  const authContext = useContext(AuthContext)
  const queryContext = useContext(QueryClientContext)
  if (!(authContext && queryContext)) throw new Error('Context must be initialized')

  const router = createRouter({
    context: {
      auth: authContext,
      query: queryContext,
    },
    defaultErrorComponent: NotFoundPage,
    defaultPreload: 'intent',
    defaultPreloadStaleTime: 0,
    routeTree,
  })

  const routerContext = useMemo(() => ({ auth: authContext }), [authContext])
  return <Provider context={routerContext} router={router} />
}
