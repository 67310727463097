/* eslint-disable @typescript-eslint/no-magic-numbers */
import scrollbar from 'tailwind-scrollbar'
import animate from 'tailwindcss-animate'
import type { Config } from 'tailwindcss/types/config'

export const palette = {
  blue: {
    100: '#E7EEF9',
    200: '#CCDAF1',
    300: '#739DE3',
    400: '#427CDC',
    500: '#0047BB',
    700: '#023281',
    950: '#0C2D64',
  },
  green: {
    100: '#E7FAF6',
    200: '#ABE3D7',
    500: '#30BC9D',
    600: '#0CAF8B',
  },
  neutral: {
    50: '#FFFFFF',
    100: '#F8F8FC',
    200: '#E7ECF5',
    300: '#D6DCE8',
    400: '#BFC5D0',
    500: '#A7ADB8',
    800: '#343C4B',
    950: '#020915',
  },
  orange: {
    500: '#FF993B',
  },
  'pastel-blue': {
    300: '#B8E5FA',
    400: '#71CBF4',
    500: '#0BBAEE',
    600: '#0B96C0',
  },
  red: {
    100: '#FFECEC',
    200: '#FFE2E2',
    500: '#FF5959',
  },
  white: '#FFFFFF',
  yellow: {
    100: '#FFF2CD',
    200: '#FFDE7E',
    500: '#FDC41F',
  },
} as const

export const colors = {
  /** blue-400 */
  accent: palette.blue[400],
  /** white */
  'accent-foreground': palette.white,
  /** neutral-100 */
  background: palette.neutral[100],
  current: 'currentColor',
  /** red-500 */
  error: palette.red[500],
  /** white */
  'error-foreground': palette.white,
  /** neutral-950 */
  foreground: palette.neutral[950],
  inherit: 'inherit',
  /** neutral-500 */
  muted: palette.neutral[500],
  /** neutral-800 */
  'muted-foreground': palette.neutral[800],
  /** white */
  popover: palette.white,
  /** neutral-950 */
  'popover-foreground': palette.neutral[950],
  /** blue-500 */
  primary: palette.blue[500],
  /** white */
  'primary-foreground': palette.white,
  /** blue-200 */
  ring: palette.blue[200],
  /** pastel-blue-500 */
  secondary: palette['pastel-blue'][500],
  /** white */
  'secondary-foreground': palette.white,
  /** green-500 */
  success: palette.green[500],
  /** white */
  'success-foreground': palette.white,
  transparent: 'transparent',
  /** yellow-500 */
  warning: palette.yellow[500],
  /** white */
  'warning-foreground': palette.white,
} as const

export const preset = {
  content: ['src/**/*.{ts,tsx,mdx}', '.storybook/**/*.{ts,tsx}'],
  plugins: [animate, scrollbar],
  theme: {
    colors,
    extend: {
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
      },
      keyframes: {
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
      },
    },
    fontFamily: {
      sans: ['proxima-nova', 'sans-serif'],
    },
  },
} satisfies Config

export default preset
