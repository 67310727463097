import { useAtom } from 'jotai'
import { type ReactNode, createContext, useMemo } from 'react'
import type { DeepReadonly } from 'ts-essentials'
import { userAtom } from '../stores/auth.store'
import type { Components } from '../types/openapi'

export type UserSessionDto = Components.Schemas.UserSessionDto

export type AuthContextType = {
  isAuthenticated: boolean
  setUser: (user: undefined | UserSessionDto) => void
  user: undefined | UserSessionDto
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined)

export function AuthProvider({ children }: DeepReadonly<{ children: ReactNode }>) {
  const [user, setUser] = useAtom(userAtom)
  const isAuthenticated = Boolean(user)

  const value = useMemo(() => ({ isAuthenticated, setUser, user }), [isAuthenticated, setUser, user])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
