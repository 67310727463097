/* eslint-disable jsdoc/require-jsdoc */
import { getPath } from './browser'
import { isBrowser } from './constants'
import { logger } from './logger'
import { fillTemplate } from './strings'

const handledLangs = ['en', 'fr'] as const

// eslint-disable-next-line prefer-named-capture-group
const langRegex = /^\/(en|fr)\//u

const defaultLang: Lang = 'fr'

export function getLangFromPath(path: string) {
  const detected = langRegex.exec(path)?.[1] ?? defaultLang
  return detected === 'fr' ? 'fr' : defaultLang
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
export let lang = getLangFromPath(/* c8 ignore next */ isBrowser ? document.location.pathname : '')

export type Lang = (typeof handledLangs)[number]

export function localePath(path: string, targetLang = lang) {
  if (!handledLangs.includes(targetLang)) {
    logger.error('unsupported lang', targetLang)
    return path
  }
  return getPath(path, targetLang === defaultLang ? '' : targetLang)
}

// eslint-disable-next-line complexity
export function handlePlural(translated: string, data?: Readonly<Record<string, unknown>>) {
  if (!translated.includes('|')) return fillTemplate(translated, data)
  // biome-ignore lint/complexity/useLiteralKeys: <explanation>
  const count = Number.parseInt(String(data?.['count'] ?? '1'), 10)
  const [a = '', b = '', c = ''] = translated.split(' | ') // eslint-disable-line id-length
  if (c.length > 0 && count > 1) return fillTemplate(c, data)
  if ((c.length > 0 && count === 1) || (b.length > 0 && count > 1)) return fillTemplate(b, data)
  return fillTemplate(a, data)
}

export function $t(message: Readonly<Record<string, string>> | string, data?: Readonly<Record<string, unknown>>) {
  const translated = typeof message === 'string' ? message : message[lang]
  if (translated === undefined) return `missing translation for message "${JSON.stringify(message)}" and lang "${lang}"`
  return handlePlural(translated, data)
}

/* c8 ignore next 8 */
export function switchLang() {
  lang = lang === 'en' ? 'fr' : 'en'
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
  const updatedPath = localePath(window.location.pathname)
  logger.info('switch lang to', lang, ', redirecting to', updatedPath)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (isBrowser) document.location.href = updatedPath
}
