import { cn, nbPercentMax } from '@collectif-energie/utils'
import * as ProgressPrimitive from '@radix-ui/react-progress'
import * as React from 'react'

const Progress = React.forwardRef<React.ElementRef<typeof ProgressPrimitive.Root>, React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>>(
  ({ className, value, ...props }, ref) => (
    <ProgressPrimitive.Root className={cn('relative h-4 w-full overflow-hidden rounded-full bg-secondary', className)} ref={ref} {...props}>
      <ProgressPrimitive.Indicator
        className="size-full flex-1 bg-primary transition-all"
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing, @typescript-eslint/strict-boolean-expressions
        style={{ transform: `translateX(-${nbPercentMax - (value || 0)}%)` }}
      />
    </ProgressPrimitive.Root>
  ),
)
Progress.displayName = ProgressPrimitive.Root.displayName

export { Progress }
