import { QueryClientProvider as Provider, QueryCache, QueryClient } from '@tanstack/react-query'
import { type ReactNode, useMemo } from 'react'
import type { DeepReadonly } from 'ts-essentials'
import { getErrorMessage } from '../utils/error.utils'
import { useSnackbarContext } from './snackbar.provider'

export function QueryProvider({ children }: DeepReadonly<{ children: ReactNode }>) {
  const { openSnackbarError } = useSnackbarContext()

  /* c8 ignore next 17 */
  // biome-ignore lint/correctness/useExhaustiveDependencies: Do NEVER watch the snackbar as dependencies
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            refetchOnWindowFocus: false,
            retry: 1,
          },
        },
        queryCache: new QueryCache({
          onError: error => {
            openSnackbarError({ message: getErrorMessage(error) })
          },
        }),
      }),
    [],
  )

  return <Provider client={queryClient}>{children}</Provider>
}
