/* eslint-disable @typescript-eslint/naming-convention */
export const API_URL = process.env.VITE_API_URL
export const API_BASE = process.env.VITE_API_BASE

export const FORM_DEV_TOOLS = process.env.VITE_FORM_DEV_TOOLS === 'true'
export const QUERY_DEV_TOOLS = process.env.VITE_QUERY_DEV_TOOLS === 'true'
export const ROUTER_DEV_TOOLS = process.env.VITE_ROUTER_DEV_TOOLS === 'true'

export const SALESFORCE_AUTH_LOGOUT_URL = process.env.VITE_SALESFORCE_AUTH_LOGOUT_URL
export const SALESFORCE_URL = process.env.VITE_SALESFORCE_URL

/** Feature flags */
export const FF_DEMETER_GUARANTEE_CAPACITY = process.env.VITE_FF_DEMETER_GUARANTEE_CAPACITY !== 'false'
