import { Button } from '@collectif-energie/ui'
import { createFileRoute, useRouter } from '@tanstack/react-router'
import { useCallback } from 'react'

export const Route = createFileRoute('/__404')({
  component: NotFoundPage,
})

export function NotFoundPage() {
  const router = useRouter()
  const history = router.history

  const goBack = useCallback(() => {
    history.back()
  }, [history])

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-6">
      <h1 className="text-9xl">Page introuvable</h1>
      <Button className="w-1/5" onClick={goBack}>
        Retour
      </Button>
    </div>
  )
}
