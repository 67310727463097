import { logger } from '@collectif-energie/utils'
import { API_BASE, API_URL, SALESFORCE_AUTH_LOGOUT_URL } from '../constants/environnement.constant'
import { cosmosApi } from './cosmos-api.service'

export async function getMe() {
  return cosmosApi.paths['/api/v1/auth/me']
    .get()
    .then(response => response.data)
    .catch((error: unknown) => {
      /* c8 ignore next 2 */
      logger.error('error', error)
    })
}

export function login() {
  window.location.href = `${API_URL}${API_BASE}/v1/auth/salesforce/login`
}

export async function logout() {
  await cosmosApi.paths['/api/v1/auth/salesforce/logout'].get()
  window.location.href = String(SALESFORCE_AUTH_LOGOUT_URL)
}
