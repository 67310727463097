/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
import { cn } from '@collectif-energie/utils'
import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp } from 'lucide-react'
import type * as React from 'react'
import { DayPicker } from 'react-day-picker'
import { Button, buttonVariants } from './button'

export type CalendarProps = React.ComponentProps<typeof DayPicker>

const buttonStyle = buttonVariants({
  className: cn('size-7 bg-transparent p-0 opacity-50 hover:bg-transparent hover:opacity-100'),
  size: 'icon',
  variant: 'ghost',
})

// eslint-disable-next-line max-lines-per-function
function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  return (
    <DayPicker
      className={cn('p-3', className)}
      classNames={{
        // biome-ignore lint/style/useNamingConvention: react-day-picker convention
        button_next: buttonStyle,
        // biome-ignore lint/style/useNamingConvention: react-day-picker convention
        button_previous: buttonStyle,
        // biome-ignore lint/style/useNamingConvention: react-day-picker convention
        caption_label: cn('text-sm font-medium'),
        day: cn('p-0'),
        month: cn('space-y-4'),
        // biome-ignore lint/style/useNamingConvention: react-day-picker convention
        month_caption: cn('flex h-7 items-center justify-center'),
        // biome-ignore lint/style/useNamingConvention: react-day-picker convention
        month_grid: cn('border-collapse space-y-1'),
        months: cn('relative flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0'),
        nav: cn('absolute z-10 flex w-full items-center justify-between px-1'),
        outside: cn('bg-accent/40'),
        // biome-ignore lint/style/useNamingConvention: react-day-picker convention
        range_end: cn('rounded-e-md bg-accent'),
        // biome-ignore lint/style/useNamingConvention: react-day-picker convention
        range_middle: cn('bg-accent first:rounded-s-md last:rounded-e-md'),
        // biome-ignore lint/style/useNamingConvention: react-day-picker convention
        range_start: cn('rounded-s-md bg-accent'),
        week: cn('mt-2 flex'),
        weekday: cn('w-9 text-xs font-normal text-muted-foreground'),
        weekdays: cn('flex'),
        weeks: cn(''),
        ...classNames,
      }}
      components={{
        // biome-ignore lint/style/useNamingConvention: <explanation>
        Chevron({ className, disabled, orientation }) {
          // eslint-disable-next-line unicorn/no-nested-ternary
          const Component = orientation === 'left' ? ChevronLeft : orientation === 'right' ? ChevronRight : orientation === 'up' ? ChevronUp : ChevronDown
          return <Component aria-disabled={disabled} className={cn('size-4 text-primary', className)} />
        },
        // biome-ignore lint/style/useNamingConvention: <explanation>
        DayButton({ className, modifiers, ...buttonProps }) {
          return (
            <Button
              className={cn(
                className,
                'size-9 p-0 font-normal',
                modifiers.today && 'bg-accent text-accent-foreground',
                modifiers.selected &&
                  'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
                modifiers.outside && 'pointer-events-none text-muted-foreground opacity-50',
                modifiers.disabled && 'text-muted-foreground opacity-50',
                modifiers.hidden && 'invisible',
                modifiers.range_middle &&
                  'rounded-none bg-accent text-accent-foreground first:rounded-s-md last:rounded-e-md hover:bg-accent hover:text-accent-foreground',
                modifiers.outside && modifiers.selected && 'bg-accent/40 text-muted-foreground',
              )}
              variant="ghost"
              {...buttonProps}
              aria-disabled={modifiers.disabled || buttonProps['aria-disabled']}
              aria-hidden={modifiers.hidden || buttonProps['aria-hidden']}
              aria-selected={modifiers.selected || buttonProps['aria-selected']}
            />
          )
        },
      }}
      showOutsideDays={showOutsideDays}
      {...props}
    />
  )
}
Calendar.displayName = 'Calendar'

export { Calendar }
