import { SvgIcon } from '@mui/material'
import { Link, useLocation } from '@tanstack/react-router'
import { type FunctionComponent, type SVGProps, useMemo } from 'react'
import type { DeepReadonly } from 'ts-essentials'
import { mergeClassNames } from '../../utils/theme.utils'

export type MenuLink = Readonly<{
  basePath: string
  disabled: boolean
  icon: FunctionComponent<SVGProps<SVGSVGElement>>
  label: string
  to: string
}>

const sx = { fontSize: 'calc(10px + 0.6vw)' }

export function AppNavigation({ links }: DeepReadonly<{ links: MenuLink[] }>) {
  const location = useLocation()

  const classNames = `hover:text-neutral-950 font-bold ${location.pathname === '/' ? 'text-white' : 'text-blue-500'}`
  const activeProperties = useMemo(() => 'border-b-2 text-neutral-950', [])

  return (
    <>
      {links.map(link => (
        <div className={classNames} key={link.to}>
          <Link
            className={mergeClassNames('flex items-center bg-transparent', link.disabled ? 'hidden' : '')}
            disabled={link.disabled}
            to={String(location.pathname.startsWith(link.basePath) ? location.pathname : link.to)}
          >
            <div className={mergeClassNames('flex items-center gap-1', location.pathname.startsWith(link.basePath) ? activeProperties : '')}>
              <SvgIcon component={link.icon} sx={sx} />
              <div className="text-xl">{link.label}</div>
            </div>
          </Link>
        </div>
      ))}
    </>
  )
}
