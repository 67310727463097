/* c8 ignore next 10 */
/**
 * Sleep for a given number of milliseconds
 * @param milliseconds the number of milliseconds to sleep
 * @returns a promise that resolves after the given number of milliseconds
 */
export async function sleep(milliseconds: number) {
  return new Promise(resolve => {
    setTimeout(resolve, milliseconds)
  })
}
