import { Analytics as tool } from 'analytics'
import { logger } from './logger'
import { sleep } from './timing'

// 30/09/2025 I wanted to import analytics dynamically like the plugins but it's not working with the static build of cosmos-front for example, it will give a blank page because the dynamic import will not be successful. This import is working, but it also mean that the analytics library will be included in the final bundle even if we don't use it. I will keep it like this for now, but we should find a way to import it dynamically in the future.

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
type AnalyticsInstance = ReturnType<typeof import('analytics').Analytics>

type OurInstance = Pick<AnalyticsInstance, 'identify' | 'page' | 'track'>

export const analytics = {
  $app: '',
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  $instance: undefined as OurInstance | undefined,
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  $pile: [] as string[],
  /* c8 ignore start */
  /**
   * Setup plugins
   * @param app - The name of the app, like 'mlm-react-front', 'cosmos-front', etc.
   * @param plugins - The list of plugins to setup
   */
  $setupPlugins: (app: string, plugins: AnalyticsInstance['plugins'][]) => {
    const options = { app, plugins }
    analytics.$app = app
    analytics.$instance = tool(options)
    logger.info(`analytics : setup app "${app}" with plugins`, plugins)
  },
  /**
   * Identify a user
   * @param userId - The user, like '123456', 'super-admin', etc.
   * @param additionalData - Additional data to identify the user, like `{ email: '...', age: 35, ... }`
   */
  identify: (userId: string, additionalData?: Record<string, unknown>) => {
    if (analytics.$instance === undefined) throw new Error('analytics : identify failed, analytics not setup')
    logger.info(`analytics : identify user "${userId}" on app "${analytics.$app}"`)
    // biome-ignore lint/complexity/noVoid: <explanation>
    void analytics.$instance.identify(userId, additionalData)
  },
  /**
   * Track a page view
   */
  page: () => {
    if (analytics.$instance === undefined) throw new Error('analytics : track page failed, analytics not setup')
    logger.debug(`analytics : track page for app "${analytics.$app}"`)
    // biome-ignore lint/complexity/noVoid: <explanation>
    void analytics.$instance.page()
  },
  /**
   * Setup analytics with Google Analytics
   * @param app - The name of the app, like 'mlm-react-front', 'cosmos-front', etc.
   * @param trackingId - The tracking ID, like 'UA-121991123'
   */
  setupGoogleAnalytics: async (app: string, trackingId: string) => {
    // @ts-expect-error missing types
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { default: plugin } = await import('@analytics/google-analytics')
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/consistent-type-assertions
    const plugins = [plugin({ trackingId })] as unknown as AnalyticsInstance['plugins'][]
    analytics.$setupPlugins(app, plugins)
  },
  /**
   * Setup analytics with Google Tag Manager
   * @param app - The name of the app, like 'mlm-react-front', 'cosmos-front', etc.
   * @param containerId - The container ID, like 'GTM-121991123'
   */
  setupGoogleTagManager: async (app: string, containerId: string) => {
    // @ts-expect-error missing types
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { default: plugin } = await import('@analytics/google-tag-manager')
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/consistent-type-assertions
    const plugins = [plugin({ containerId })] as unknown as AnalyticsInstance['plugins'][]
    analytics.$setupPlugins(app, plugins)
  },
  /* c8 ignore stop */
  /**
   * Setup analytics with in memory tracking, useful for tests
   * @param app - The name of the app, like 'mlm-react-front', 'cosmos-front', etc.
   */
  setupInMemory: (app: string) => {
    analytics.$app = app
    /* eslint-disable jsdoc/require-jsdoc */
    const addToPile = async (item: string, data?: Record<string, unknown>) => {
      logger.debug(`analytics : add "${item}" to pile`, data)
      await sleep(1)
      analytics.$pile.push(item)
      logger.debug(`analytics : pile for app "${app}"`, analytics.$pile)
    }
    analytics.$instance = {
      identify: async (userId: string, additionalData?: Record<string, unknown>) => {
        await addToPile(`identify:${userId}`, additionalData)
      },
      page: async () => {
        await addToPile('page')
      },
      track: async (event: string, additionalData?: Record<string, unknown>) => {
        await addToPile(`track:${event}`, additionalData)
      },
    }
    logger.info(`analytics : setup app "${app}" with in memory tracking`)
    /* eslint-enable jsdoc/require-jsdoc */
  },
  /**
   * Track an event
   * @param event - The event name like 'click', 'download-invoice', etc.
   * @param additionalData - Additional data to track the event, like `{ category: '...', label: '...', ... }`
   */
  track: (event: string, additionalData?: Record<string, unknown>) => {
    if (analytics.$instance === undefined) throw new Error('analytics : track event failed, analytics not setup')
    logger.debug(`analytics : track event "${event}" for app "${analytics.$app}"`)
    // biome-ignore lint/complexity/noVoid: <explanation>
    void analytics.$instance.track(event, additionalData)
  },
}
