/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { palette as palettes } from '@collectif-energie/ui'
import type {} from '@mui/lab/themeAugmentation'
import type { Components, Theme } from '@mui/material/styles'
import type {} from '@mui/x-charts/themeAugmentation'
import type {} from '@mui/x-data-grid/themeAugmentation'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import arrowDown from '../assets/svg/arrow-small-down.svg?react'
import arrowUp from '../assets/svg/arrow-small-up.svg?react'
import arrowUpDown from '../assets/svg/arrow-up-down.svg?react'
import bergerMenu from '../assets/svg/burger-menu.svg?react'
import calendar from '../assets/svg/calendar.svg?react'
import checkedDisabled from '../assets/svg/checkbox/checked-disabled.svg?react'
import chevronDown from '../assets/svg/chevron-down.svg?react'
import chevronLeft from '../assets/svg/chevron-left.svg?react'
import chevronRight from '../assets/svg/chevron-right.svg?react'
import eyeSlash from '../assets/svg/eye-slash.svg?react'
import funnel from '../assets/svg/funnel.svg?react'
import viewColumns from '../assets/svg/view-columns.svg?react'

export const components = {
  MuiAlert: {
    styleOverrides: {
      colorError: palettes.red[500],
      colorInfo: palettes['pastel-blue'][400],
      colorSuccess: palettes.green[500],
      colorWarning: palettes.yellow[500],
      root: {
        alignItems: 'center',
        border: 'none',
      },
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      disableClearable: true,
      loadingText: 'Chargement',
      noOptionsText: 'Rechercher un client',
    },
    styleOverrides: {
      noOptions: {
        padding: '0.5rem',
      },
      root: {
        '& .MuiAutocomplete-endAdornment': {
          backgroundColor: palettes.blue[500],
          border: `1px solid ${palettes.neutral[500]}`,
          borderRadius: '0 5px 5px 0',
          display: 'flex',
          height: '100%',
          padding: '0.25rem',
          right: '1px !important',
        },
        '& .MuiAutocomplete-input': {
          color: palettes.neutral[800],
          fontWeight: 'bold',
          padding: '0.5rem',
        },
        '& .MuiOutlinedInput-root': {
          padding: '0.5rem',
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      variant: 'contained',
    },
    styleOverrides: {
      containedPrimary: {
        '&:disabled': { backgroundColor: palettes.blue[100], color: palettes.neutral[500] },
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: palettes.blue[950],
          color: palettes.white,
        },
        backgroundColor: palettes.blue[500],
        color: palettes.white,
      },
      containedSecondary: {
        '&:disabled': { backgroundColor: palettes.blue[100], color: palettes.neutral[500] },
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: palettes.blue[950],
          border: `1px solid ${palettes.blue[200]}`,
          color: palettes.blue[200],
        },
        backgroundColor: palettes.white,
        border: `1px solid ${palettes.blue[500]}`,
        color: palettes.blue[500],
      },
      outlinedPrimary: {
        '&:disabled': { backgroundColor: palettes.blue[100], color: palettes.neutral[500] },
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: palettes.blue[950],
          border: `1px solid ${palettes.blue[200]}`,
          color: palettes.blue[200],
        },
        backgroundColor: palettes.white,
        border: `1px solid ${palettes.blue[500]}`,
        color: palettes.blue[500],
      },
      outlinedSecondary: {
        '&:disabled': { backgroundColor: palettes.blue[100], color: palettes.neutral[500] },
        '&:hover, &.Mui-focusVisible': {
          border: 'none',
          fontWeight: 900,
        },
        backgroundColor: 'transparent',
        border: 'none',
        color: palettes.blue[500],
      },
      root: {
        '&:hover': { boxShadow: 'none' },
        borderRadius: 25,
        boxShadow: 'none',
        fontWeight: 'bold',
        padding: '0.5rem 1rem',
      },
    },
    variants: [
      {
        props: { variant: 'active' },
        style: {
          '&:disabled': { backgroundColor: palettes.blue[100], color: palettes.neutral[500] },
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: palettes.blue[950],
          },
          backgroundColor: palettes.neutral[950],
          color: palettes.white,
        },
      },
    ],
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderColor: palettes.blue[200],
        borderRadius: '0.5rem',
        boxShadow: 'none',
        padding: '1rem',
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        color: palettes.blue[500],
        display: 'flex',
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: '0.25rem 0.5rem',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      colorError: {
        backgroundColor: palettes.red[500],
        color: palettes.white,
        fontWeight: 'bold',
      },
      colorInfo: {
        backgroundColor: palettes.neutral[500],
        color: palettes.white,
        fontWeight: 'bold',
      },
      colorPrimary: {
        backgroundColor: palettes.blue[500],
        color: palettes.white,
        fontWeight: 'bold',
      },
      colorSuccess: {
        backgroundColor: palettes.green[600],
        color: palettes.white,
        fontWeight: 'bold',
      },
      colorWarning: {
        backgroundColor: palettes.yellow[500],
        color: palettes.white,
        fontWeight: 'bold',
      },
      root: {
        height: 'fit-content',
        padding: '0.5rem',
      },
    },
    variants: [
      {
        props: { color: 'orange', variant: 'filled' },
        style: {
          backgroundColor: palettes.orange[500],
          color: palettes.white,
          fontWeight: 'bold',
        },
      },
      {
        props: { color: 'secondary', variant: 'outlined' },
        style: {
          backgroundColor: palettes.blue[100],
          border: 'unset',
          color: palettes.blue[500],
        },
      },
      {
        props: { color: 'success', variant: 'outlined' },
        style: {
          backgroundColor: palettes.green[100],
          border: 'unset',
          color: palettes.green[600],
        },
      },
      {
        props: { color: 'info', variant: 'outlined' },
        style: {
          backgroundColor: palettes.neutral[100],
          border: 'unset',
          color: palettes.neutral[500],
        },
      },
      {
        props: { color: 'warning', variant: 'outlined' },
        style: {
          backgroundColor: palettes.yellow[100],
          border: 'unset',
          color: palettes.yellow[500],
        },
      },
      {
        props: { variant: 'unstyled' },
        style: {
          backgroundColor: 'transparent',
          border: 'unset',
          color: palettes.neutral[800],
        },
      },
    ],
  },
  MuiDataGrid: {
    defaultProps: {
      density: 'compact',
      disableRowSelectionOnClick: true,
      getCellClassName: () => 'font-bold',
      getRowHeight: () => 'auto',
      slots: {
        columnMenuFilterIcon: funnel,
        columnMenuHideIcon: eyeSlash,
        columnMenuIcon: bergerMenu,
        columnMenuManageColumnsIcon: viewColumns,
        columnMenuSortAscendingIcon: arrowUp,
        columnMenuSortDescendingIcon: arrowDown,
        columnSortedAscendingIcon: arrowUpDown,
        columnSortedDescendingIcon: arrowUpDown,
      },
    },
    styleOverrides: {
      cell: {
        padding: '0.5rem 0.25rem',
      },
      columnHeaders: {
        backgroundColor: palettes.blue[500],
        borderRadius: '0.5rem 0.5rem 0 0',
        color: palettes.white,
        minHeight: 'fit-content',
        padding: '1rem 0',
      },
      columnHeaderTitle: {
        fontWeight: 900,
      },
      menuIcon: {
        color: palettes.white,
        fontWeight: 900,
        transform: 'scale(0.5)',
      },
      menuIconButton: {
        color: palettes.white,
        fontWeight: 900,
      },
      root: {
        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-cell:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-columnHeader:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-columnHeader:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        border: `1px solid ${palettes.blue[200]}`,
        borderRadius: '0.5rem',
      },
      row: {
        maxHeight: 'fit-content !important',
        minHeight: 'fit-content !important',
      },
      sortIcon: {
        color: palettes.white,
        fontWeight: 900,
        transform: 'scale(0.5)',
      },
      toolbarContainer: {
        padding: '0.25rem',
      },
    },
  },
  MuiDateCalendar: {
    defaultProps: {
      slots: {
        leftArrowIcon: checkedDisabled,
      },
    },
    styleOverrides: {
      root: {
        '& .MuiDayCalendar-monthContainer': {
          position: 'relative',
        },
        maxWidth: '25rem',
      },
    },
  },
  MuiDatePicker: {
    defaultProps: {
      slots: {
        leftArrowIcon: chevronLeft,
        openPickerIcon: calendar,
        rightArrowIcon: chevronRight,
        switchViewIcon: chevronDown,
      },
      yearsPerRow: 3,
    },
  },
  MuiDayCalendar: {
    styleOverrides: {
      root: {
        maxWidth: '25rem',
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: palettes.neutral[500],
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        '&.Mui-error': {
          margin: 0,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: palettes.neutral[800],
        fontWeight: 'bold',
      },
    },
  },
  MuiIcon: {
    styleOverrides: {
      colorError: palettes.red[500],
      colorPrimary: palettes.blue[500],
      colorSecondary: palettes['pastel-blue'][600],
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '&.MuiOutlinedInput-root.Mui-disabled': {
          backgroundColor: palettes.neutral[300],
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: palettes.neutral[800],
        fontWeight: 'bold',
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        padding: '0.5rem 0.75rem',
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        transform: 'scale(0.5)',
      },
    },
  },
  MuiLoadingButton: {
    defaultProps: {
      variant: 'contained',
    },
  },
  MuiMenu: {
    styleOverrides: {
      root: {
        '& .MuiMenu-list': {
          padding: '0',
        },
        '& .MuiMenu-paper': {
          borderRadius: '5px',
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        color: palettes.neutral[800],
        fontWeight: 'bold',
        padding: '0 0.5rem',
      },
    },
  },
  MuiMonthCalendar: {
    styleOverrides: {
      root: {
        maxWidth: '25rem',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        padding: '0.5rem',
      },
      notchedOutline: {
        borderColor: palettes.neutral[500],
        borderWidth: '2px',
        color: palettes.neutral[800],
      },
      root: {
        '& .Mui-disabled': {
          backgroundColor: palettes.neutral[300],
        },
        '& .MuiIconButton-root': {
          marginRight: 0,
        },
        '& .MuiOutlinedInput-input': {
          fontWeight: '600',
        },
        '& ::placeholder': {
          color: palettes.neutral[800],
          fontWeight: 'bold',
        },
        borderRadius: '5px',
        padding: '0.5rem',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        border: `2px solid ${palettes.blue[400]}`,
        borderRadius: '5px',
        color: palettes.neutral[800],
        fontWeight: 'bold !important',
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      root: {
        margin: 0,
        maxHeight: 'fit-content',
        minHeight: 'fit-content',
        padding: '1rem',
      },
    },
  },
  MuiPopper: {
    styleOverrides: {
      root: {
        borderRadius: '5px',
        fontWeight: 'bold',
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: { padding: 0 },
    },
  },
  MuiSelect: {
    defaultProps: {
      IconComponent: chevronDown,
    },
    styleOverrides: {
      icon: {
        width: '1.5rem',
      },
      root: {
        borderRadius: '5px',
        color: palettes.neutral[800],
        fontWeight: 'bold',
      },
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      root: {
        height: '100%',
        transform: 'none',
      },
    },
  },
  MuiSvgIcon: {
    defaultProps: {
      inheritViewBox: true,
    },
    styleOverrides: {
      root: {
        fill: 'transparent',
        fontSize: 'calc(6px + 0.6vw)',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          border: '2px solid #0047BB',
          borderRadius: '7px 7px 0 0',
        },
        border: 'none',
        borderBottom: `2px solid ${palettes.neutral[500]}`,
        fontSize: '1em',
        fontWeight: 'bolder',
        minHeight: 'fit-content',
      },
    },
  },
  MuiTabs: {
    defaultProps: {},
    styleOverrides: {
      root: {
        minHeight: 'fit-content',
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        color: palettes.neutral[800],
        fontWeight: 'bold',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: palettes.neutral[100],
        color: palettes.neutral[800],
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
      },
    },
  },
  MuiYearCalendar: {
    styleOverrides: {
      root: {
        maxWidth: '25rem',
      },
    },
  },
} satisfies Components<Omit<Theme, 'components'>>
