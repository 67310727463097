/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-statements */
import { functionReturningUndefined } from '@collectif-energie/utils'
import { Alert, type AlertColor, type AlertPropsColorOverrides, Box, Button, Icon, Snackbar, type SnackbarOrigin } from '@mui/material'
import type { OverridableStringUnion } from '@mui/types'
import React, { useCallback, useMemo, useState } from 'react'
import type { DeepReadonly } from 'ts-essentials'

interface State extends SnackbarOrigin {
  icon: React.ReactNode | undefined
  isOpen: boolean
  message: React.ReactNode | string
  severity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>
  title?: string
}

const sx = { width: '100%' }

/* c8 ignore next 7 */
const SnackbarContext = React.createContext({
  closeSnackbar: functionReturningUndefined,
  isOpen: false,
  // biome-ignore lint/suspicious/noEmptyBlockStatements: needed here
  openSnackbar: (_parameters: DeepReadonly<Partial<State>>) => {},
  // biome-ignore lint/suspicious/noEmptyBlockStatements: needed here
  openSnackbarError: (_parameters: DeepReadonly<Partial<State>>) => {},
  /* c8 ignore next 2 */
  // biome-ignore lint/suspicious/noEmptyBlockStatements: needed here
  openSnackbarSuccess: (_parameters: DeepReadonly<Partial<State>>) => {},
})

function getColorBySeverity(severity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides> | undefined) {
  switch (severity) {
    case 'error': {
      return 'red'
    }
    case 'info': {
      return 'pastel-blue'
    }
    case 'success': {
      return 'green'
    }
    case 'warning': {
      return 'yellow'
    }
    default: {
      return 'blue'
    }
  }
}

function getAlertClassNames(severity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides> | undefined) {
  return `flex align-center shadow-md rounded-md border border-b-4 border-${getColorBySeverity(severity)}-500`
}

function getMessageClassNames(severity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides> | undefined) {
  return `font-bold text-${getColorBySeverity(severity)}-500`
}

function getTitleClassNames(severity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides> | undefined) {
  return `font-bold text-${getColorBySeverity(severity)}-500`
}

export function useSnackbarContext() {
  return React.useContext(SnackbarContext)
}

export function SnackbarProvider({ children }: DeepReadonly<{ children: React.ReactNode }>) {
  const [state, setState] = useState<State>({
    horizontal: 'right',
    icon: <Icon fontSize="small">info</Icon>,
    isOpen: false,
    message: '',
    severity: 'info',
    title: '',
    vertical: 'top',
  })
  const { horizontal, icon, isOpen, message, severity, title, vertical } = state

  /* c8 ignore start */
  const closeSnackbar = useCallback(() => {
    setState({ ...state, isOpen: false })
    // eslint-disable-next-line unicorn/no-useless-undefined
    return undefined
  }, [state])
  const openSnackbar = useCallback(
    (parameters: DeepReadonly<Partial<State>>) => {
      setState({ ...state, icon: <Icon fontSize="small">info</Icon>, isOpen: true, severity: 'info', title: '', ...parameters })
    },
    [state],
  )
  const openSnackbarSuccess = useCallback(
    (parameters: DeepReadonly<Partial<State>>) => {
      setState({ ...state, icon: <Icon fontSize="small">verified</Icon>, isOpen: true, severity: 'success', title: '', ...parameters })
    },
    [state],
  )
  /* c8 ignore stop */
  const openSnackbarError = useCallback(
    (parameters: DeepReadonly<Partial<State>>) => {
      setState({ ...state, icon: <Icon fontSize="small">cancel</Icon>, isOpen: true, severity: 'error', title: 'Une erreur est survenue', ...parameters })
    },
    [state],
  )

  const anchorOrigin = useMemo(() => ({ horizontal, vertical }), [horizontal, vertical])
  const value = useMemo(
    () => ({ closeSnackbar, isOpen, openSnackbar, openSnackbarError, openSnackbarSuccess }),
    [closeSnackbar, openSnackbar, openSnackbarError, isOpen, openSnackbarSuccess],
  )

  const closeAction = useMemo(
    () => (
      <Button color="secondary" onClick={closeSnackbar} size="small" variant="text">
        <Icon fontSize="small">close</Icon>
      </Button>
    ),
    [closeSnackbar],
  )

  const getAutoHideDuration = useCallback(() => {
    const defaultAutoHideDuration = 6000
    // eslint-disable-next-line unicorn/no-null
    return severity === 'error' ? null : defaultAutoHideDuration
  }, [severity])

  return (
    <SnackbarContext.Provider value={value}>
      <Box>
        {children}
        <Snackbar anchorOrigin={anchorOrigin} autoHideDuration={getAutoHideDuration()} data-testid="snackbar" onClose={closeSnackbar} open={isOpen}>
          <div className={getAlertClassNames(severity)}>
            <Alert action={closeAction} icon={icon} onClose={closeSnackbar} severity={severity} sx={sx} variant="standard">
              {Boolean(title) && <div className={getTitleClassNames(severity)}>{title}</div>}
              <span className={getMessageClassNames(severity)}>{message}</span>
            </Alert>
          </div>
        </Snackbar>
      </Box>
    </SnackbarContext.Provider>
  )
}
